import React from 'react'
import Navbar from '../components/navbar'
import Contactone from '../components/contact/contactone'
import { FloatingWhatsApp } from "react-floating-whatsapp";
import LogoOne from "../../static/images/favicon.png";


// import Contacttwo from '../components/contact/contacttwo'
import contacttwo from '../components/contact/contacttwo'
import Footer from '../components/footer'

export default function contact() {
  return (
    <div>
      <div>
        <FloatingWhatsApp
          phoneNumber="+91 9930522133"
          accountName="RJ Actuaries & Consultants"
          allowEsc
          allowClickAway
          notification
          notificationSound
          avatar={LogoOne}
        />
      </div>
      <Navbar/>
        <Contactone/>
        {/* <Contacttwo/> */}
        <Footer/>
    </div>
  )
}
